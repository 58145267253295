import React, { Component } from 'react';
import { withApollo, graphql, Query } from 'react-apollo';
import {  
    Container, 
    Row, 
    Col,
    Media,
    Button,
    Dropdown
 } from 'react-bootstrap/';
import ItemListaPedido from "../component/ItemListaPedido";
import meusPedidos from "../assets/images/meusPedidos.png";
import {  MEUS_PEDIDOS  } from '../services/graphql/querys/pedido';

class MeusPedidos extends Component {
    constructor(props){
        super(props);
        this.state = {
            pedidos: false,
            filter: ""
        }

        this.trogleMenu = this.trogleMenu.bind(this);
    }
    trogleMenu = () => {
        this.props.trogleMenu()
    }

    componentWillMount(){
        console.log("CHEGUEI NO MOUNT")
            this.props.client.query({query: MEUS_PEDIDOS, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                    console.log("DATA: ", data)
                    if(!!data && !!data.MyPurchaseOrdersQuery && !!data.MyPurchaseOrdersQuery.data){
                        this.setState({pedidos: data.MyPurchaseOrdersQuery.data})
                    } 
                } else {
                    console.log("resp query: ",data.MyPurchaseOrdersQuery.data)
                    this.setState({pedidos: data.MyPurchaseOrdersQuery.data})
                }
            })
    }

    render(){
        return (
            <Container fluid>
                <div className="alignCenter">
                    <Row>
                        <Col xs={12} sm={12}>
                            <h4>Meus Pedidos</h4>
                        </Col>
                        <Col xs={12} sm={12}>
                            <Dropdown className="filtroMeusPedidos">
                                {
                                this.state.filter === '' ?
                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">Todos</Dropdown.Toggle>
                                : this.state.filter === 'created' ?
                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">Criado - Aguardando análise</Dropdown.Toggle>
                                : this.state.filter === 'sended' ?
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic">Enviado ao cliente</Dropdown.Toggle>
                                : this.state.filter === 'delivered' &&
                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">Entregue - finalizado</Dropdown.Toggle>
                                }
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={ () => this.setState({filter: ''}) }>Todos</Dropdown.Item>
                                    <Dropdown.Item onClick={ () => this.setState({filter: 'created'}) }>Criado - Aguardando análise</Dropdown.Item>
                                    <Dropdown.Item onClick={ () => this.setState({filter: 'sended'}) }>Enviado ao cliente</Dropdown.Item>
                                    <Dropdown.Item onClick={ () => this.setState({filter: 'delivered'}) }>Entregue - finalizado</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </div>
                {/* <div className="boxLineGreen">
                    <Media className="contentMeusPedidos">
                        <img 
                            width={52}
                            height={52}
                            className="mr-3"
                            src={meusPedidos}
                            alt="Generic placeholder"/>
                        <Media.Body>
                            Seu pedido <strong>Nº 09480988</strong>
                            <br />
                            <strong>chega hoje =)</strong>
                        </Media.Body>
                    </Media>
                </div> */}
                <Row>
                {
                    !!this.state.pedidos && this.state.pedidos.map(
                        pedido => {
                            console.log("Pedido", pedido);
                            if(!!pedido && (this.state.filter === '' || (pedido.status && this.state.filter === pedido.status.code))){
                                return <Col lg={6}>
                                    <ItemListaPedido pedido={pedido}/>
                                </Col>
                            }
                        }
                    )
                }
                </Row>
            </Container>
        );
    }
}
export default withApollo(MeusPedidos);