import React from 'react';
export const Sticker = <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 20 20" version="1.1">
    <g id="Symbols" stroke="none" fill="none">
        <g className="iconSticker" transform="translate(-4.000000, -4.000000)" fill="#000000">
            <g transform="translate(4.000000, 4.000000)">
                <path d="M18.8888889,9.44444444 L18.8888889,5 C18.8888889,2.85222598 17.147774,1.11111111 15,1.11111111 L5,1.11111111 C2.85222598,1.11111111 1.11111111,2.85222598 1.11111111,5 L1.11111111,15 C1.11111111,17.147774 2.85222598,18.8888889 5,18.8888889 L10.5555556,18.8888889 C11.4760301,18.8888889 12.2222222,18.1426968 12.2222222,17.2222222 L12.2222222,15 C12.2222222,12.852226 13.9633371,11.1111111 16.1111111,11.1111111 L17.2222222,11.1111111 C18.1426968,11.1111111 18.8888889,10.364919 18.8888889,9.44444444 Z M18.8073608,11.7258369 C18.3578367,12.0387579 17.8114547,12.2222222 17.2222222,12.2222222 L16.1111111,12.2222222 C14.5769868,12.2222222 13.3333333,13.4658757 13.3333333,15 L13.3333333,17.2222222 C13.3333333,17.6914287 13.2169994,18.1334639 13.0116018,18.5210578 C16.054457,17.5839336 18.3540739,14.9511627 18.8073608,11.7258369 L18.8073608,11.7258369 Z M5,0 L15,0 C17.7614238,0 20,2.23857626 20,5 L20,10.5555556 C20,15.7715782 15.7715782,20 10.5555556,20 L5,20 C2.23857626,20 0,17.7614238 0,15 L0,5 C0,2.23857626 2.23857626,0 5,0 Z" id="Shape"/>
            </g>
        </g>
    </g>
</svg>;

export const Close = <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 18 18" version="1.1">
    <defs>
        <path d="M15.7568,14 L22.6364,7.1204 C23.1212,6.6356 23.1212,5.8484 22.6364,5.3636 C22.1516,4.8788 21.3644,4.8788 20.8796,5.3636 L14,12.2432 L7.1204,5.3636 C6.6356,4.8788 5.8484,4.8788 5.3636,5.3636 C4.8788,5.8484 4.8788,6.6356 5.3636,7.1204 L12.2432,14 L5.3636,20.8796 C4.8788,21.3644 4.8788,22.1516 5.3636,22.6364 C5.8484,23.1212 6.6356,23.1212 7.1204,22.6364 L14,15.7568 L20.8796,22.6364 C21.3644,23.1212 22.1516,23.1212 22.6364,22.6364 C23.1212,22.1516 23.1212,21.3644 22.6364,20.8796 L15.7568,14 Z" id="path-1"/>
    </defs>
    <g id="Symbols" stroke="none" fill="none">
        <g  transform="translate(-5.000000, -5.000000)">
            <mask id="mask-2" fill="white">
                <use className="iconClose" xlinkHref="#path-1"/>
            </mask>
            <use className="iconClose" fill="#000000" xlinkHref="#path-1"/>
        </g>
    </g>
</svg>;

export const Rectangle = <svg xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" width="12px" height="9px" viewBox="0 0 12 9" version="1.1">
    <title>Rectangle</title>
    <g id="Carrinho" stroke="none" fill="none">
        <g transform="translate(-174.000000, -496.000000)" fill="#281D0F" id="Rectangle">
            <path d="M178.93934,496.43934 L181.37868,494 C182.081866,493.296814 183.221956,493.296814 183.925142,494 C184.185484,494.260342 184.359762,494.594235 184.424488,494.95668 L185.434857,500.614403 C185.629041,501.70177 184.904973,502.740672 183.817607,502.934857 C183.585045,502.976388 183.346965,502.976388 183.114403,502.934857 L177.45668,501.924488 C176.477713,501.749662 175.825828,500.814328 176.000654,499.83536 C176.06538,499.472915 176.239658,499.139022 176.5,498.87868 L178.93934,496.43934 Z" transform="translate(180.000000, 497.500000) rotate(-315.000000) translate(-180.000000, -497.500000) "/>
        </g>
    </g>
</svg>;