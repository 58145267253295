import gql from 'graphql-tag';

export const MEUS_PEDIDOS = gql`
query MyPurchaseOrdersQuery($id: Int){
  MyPurchaseOrdersQuery(id: $id){
    data{
      id
      created_at
      commercial_round{
        description
        id
      }
      status{
        code
        description
        posible_statuses{
          code
          description
        }
      }
      items{
        id
        quantity
        quantity_removed
        status{
          code
          description
        }
        unit_price
        product{
          product_id
          comercial_name
          picture
          urlPicture
        }
      }
    }
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
}`;


