import React, { Component } from 'react';
import { withApollo, graphql, Query } from 'react-apollo';
import {  MEUS_PEDIDOS  } from '../services/graphql/querys/pedido';

import { 
    Button, 
    Container, 
    Row, 
    Col 
} from 'react-bootstrap/';
import ItemPedido from "../component/ItemPedido";


class DetalheCompra extends Component {
    constructor(props){
        super(props);
        this.state = {
            emAberto: true,
            pedido: false,
        }
    }
    trogleStatus = () => {
        this.setState({emAberto: !this.state.emAberto});
    }
    componentWillMount(){
        console.log("CHEGUEI NO MOUNT")
            
            this.props.client.query({query: MEUS_PEDIDOS, variables: {id: this.props.match.params.id}, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                } else {
                    console.log("resp query: ",data.MyPurchaseOrdersQuery.data)
                    this.setState({pedido: data.MyPurchaseOrdersQuery.data[0]})
                }
            })
    }

    calculaTotal(){
        let tot = 0;
        this.state.pedido.items.map(item => (
            tot = tot + ((item.quantity+item.quantity_removed) * item.unit_price)
        ))
        return tot;
    }
    calculaTotalEnviado(){
        let tot = 0;
        this.state.pedido.items.map(item => {
            if(item.quantity > 0)
            tot = tot + (item.quantity * item.unit_price)
        })
        return tot;
    }

    render(){
        // console.log("MEUS PROPS", this.props)
        console.log("MEUS PROPS", this.state.pedido)
        const statusCode = [
            "closed",
            "delivered",
            "fully_delivered",
            "partially_delivered",
            "received",
            "sended",
        ]
        let total = 0.0;
        if(!!this.state.pedido){
            return (
                <>
                    <div className="Header bgGreen">
                        <div className="Content colorWhite">
                            <div>
                                <strong>{
                                    statusCode.indexOf(this.state.pedido.status.code) !== -1 ?
                                    `Total da compra entregue: R$${(this.calculaTotalEnviado()).toFixed(2)}`
                                    :
                                    `Total da compra: R$${(this.calculaTotal()).toFixed(2)}`
                                }</strong>
                            </div>
                            {
                                this.state.pedido && this.state.pedido.commercial_round && this.state.pedido.commercial_round.description && 
                                <div>
                                    Nome Rodada: <strong>{this.state.pedido.commercial_round.description}</strong>
                                </div>
                            }
                            {
                                this.state.pedido && this.state.pedido.id && 
                                <div>
                                    Pedido Nº: <strong>{this.state.pedido.id}</strong>
                                </div>
                            }
                            {
                                this.state.pedido && this.state.pedido.created_at && 
                                <div>
                                    Data: <strong>{this.state.pedido.created_at}</strong>
                                </div>
                            }
                            {
                                this.state.pedido && this.state.pedido.status && this.state.pedido.status.description && 
                                <div>
                                    Status pedido: {this.state.pedido.status.description}
                                </div>
                            }
                        </div>
                    </div>
                    <Container fluid>
                        <h5 className="title">Você comprou - <span style={{fontWeight: "normal"}}>Total: R${(this.calculaTotal()).toFixed(2)}</span></h5>
                        
                        <Row>
                        {
                            this.state.pedido.items.map(
                                (item, key) => {
                                    return <Col lg={4} key={`item-pedido-${key}`}>
                                        <ItemPedido item={item} />
                                    </Col>
                                }
                            )
                        }
                        </Row>
                    </Container>
                    {
                        statusCode.indexOf(this.state.pedido.status.code) !== -1 &&
                        this.calculaTotalEnviado() > 0 &&
                        <Container fluid>
                            <h5 className="title">Você recebeu - <span style={{fontWeight: "normal"}}>Total: R${(this.calculaTotalEnviado()).toFixed(2)}</span></h5>
                            
                            <Row>
                            {
                                this.state.pedido.items.map(
                                    (item, key) => {
                                        console.log("ITEM: ", this.calculaTotalEnviado())
                                        if(item.quantity > 0){
                                            return (
                                                <Col lg={4} key={`item-pedido-e-entregue-${key}`}>
                                                    <ItemPedido item={item} recebido />
                                                </Col>
                                            )
                                        }
                                    }
                                )
                            }
                            </Row>
                        </Container>
                    }
                </>
            );
        }else{
            return <div></div>
        }
    }
}
export default withApollo(DetalheCompra);