import React, { Component } from 'react';
import Outros from "../assets/images/Outros.png";
import Folhas from "../assets/images/Folhas.png";
import Frutas from "../assets/images/Frutas.png";
import Legumes from "../assets/images/Legumes.png";
import Panc from "../assets/images/Panc.png";
import Temperros from "../assets/images/Temperros.png";
import Todos from "../assets/images/Todos.png";
import Processado from "../assets/images/Processado.png";


class Categoria extends Component {
    buscaImgCat(cat){
        if(cat == 1){
            return Processado; //processado
        }else if(cat == 2){
            return Legumes;
        }else if(cat == 3){
            return Frutas;
        }else if(cat == 4){
            return Folhas;
        }else if(cat == 5){
            return Panc;
        }else if(cat == 6){
            return Temperros;
        }else if(cat == 99){
            return Outros;
        }else{
            return "https://crestana.com.br/img/imagens_produto/20190726_200300_1____01%20PRODUTO-SEM-IMAGEM-1000X1000.JPG";
        }
    }
    render(){
        return(

            
            <a href={`/home-rodada/`+ this.props.roundId + "/" + (this.props.categoria == 'TODAS'  ? 0 : this.props.categoria.product_category_id)} className="itemCategoria">
                <div className="imageCategoria" >
                    <img src={this.props.categoria == 'TODAS'  ? Todos : this.buscaImgCat(this.props.categoria.product_category_id)} />
                </div>
                <span><center>{this.props.categoria == 'TODAS' ? "TODAS" :this.props.categoria.product_category }</center></span>
            </a>

        )
    }
}

export default Categoria;