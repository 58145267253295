import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap/';
import ItemCarrinho from "../component/ItemCarrinho";
import { withApollo, graphql, Query } from 'react-apollo';

import {  FAZ_PEDIDO  } from '../services/graphql/mutations/pedido';


class Carrinho extends Component {
    constructor(props){
        super(props);
        this.state = {
            search: 'pedido',
            id: this.props.match.params.id,
            itensCarrinho : null,
            loading: false,
        }
    }
    componentWillMount(){
        let chart = localStorage.getItem('chart') ? JSON.parse(localStorage.getItem('chart')) : null;
        if(chart && chart[this.state.id]){
            this.setState({itensCarrinho: chart[this.state.id]});
        }else{
            this.setState({itensCarrinho: []});
        }           
    }
    changeQtd = (indice,qtd) => {
        let items = this.state.itensCarrinho;
        items[indice].qtd = qtd;
        this.setState({itensCarrinho: items})
    }
    total(){
        let tot = 0;
        this.state.itensCarrinho.map(item => (
            tot = tot + (item.qtd * item.product.unit_price)
        ))
        return tot;
    }

    submitIt = () => {
        let body = this.state;
        let paramsEnviar = {};
        let produtos = [];
        let loading = this.state.loading;
        if(!this.state.loading) {
            this.setState({loading: true});
            body.itensCarrinho.map(item => (
                produtos.push({quantity: item.qtd, product_id: item.product.product.product_id})
            ))
            paramsEnviar.commercial_round_id = this.props.match.params.id;
            paramsEnviar.products = produtos;
            console.log("Enviar Parametros: ", paramsEnviar);

            this.props.client.mutate(
                {
                    mutation: FAZ_PEDIDO, 
                    variables: paramsEnviar,
                    errorPolicy: 'all',
                }
            )
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                    this.setState({loading: false});
                    
                    alert(errors[0].extensions.validation.commercial_round_id);
                } else {
                    console.log("DATA: ", data);
                    localStorage.removeItem('chart');
                    localStorage.removeItem('roundId');

                    alert("Seu pedido será realizado mediante o pagamento !");
                    this.setState({loading: false});
                    window.location.href = `/pedido-concluido/` + data.createPurchaseOrder.id;
                }
            })
        }
    }

    render(){
        return (
            <Container fluid>
                <Row>
                {
                    this.state.itensCarrinho && this.state.itensCarrinho.map(
                        (item, ind) => (
                            <Col lg={4} key={`ik-${item.product.product.product_id}`}>
                                <ItemCarrinho item={item} changeQtd={this.changeQtd} indice={ind}/>
                            </Col>
                        )
                    )
                }
                </Row>
                <Row className="contentCenter">
                    <Col xs={12} sm={12}>
                        <sub>Você tem {this.state.itensCarrinho.length} produtos no seu carrinho</sub>
                    </Col>
                    <Col className="total" xs={6} sm={6}>
                        <strong>Total</strong>
                    </Col>
                    <Col className="valor" xs={6} sm={6}>
                        R$<strong>{this.total().toFixed(2)}</strong>
                    </Col>
                </Row>
                <Row className="contentCenter">
                    <Col>
                        <Button variant="custom" onClick={() => this.submitIt()}>Fazer meu pedido</Button>
                    </Col>
                </Row>
            </Container>
        );
    }
}
export default withApollo(Carrinho);