import React, { Component } from 'react';
import { ApolloProvider } from "react-apollo";
import apolloClient from "./services/apollo";

import Routes from './routes';
import './App.css';

class App extends Component {
  render(){
    return (
      <ApolloProvider client={apolloClient}>
        <div className="App">
          <Routes />
        </div>
      </ApolloProvider>
    );
  }
}
export default App;
