import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap/';
import ItemPedido from "../component/ItemPedido";

const itensPedidos = [
    {
        img: 'https://static3.tcdn.com.br/img/img_prod/450860/muda_de_maca_eva_para_climas_quentes_1m_enxertadas_562_1_20190611093558.jpg',
        name: 'Maçã',
        peso: 8,
        valor: '78,90',
    },
    {
        img: 'https://principalessencias.com.br/media/catalog/product/cache/1/image/550x550/9df78eab33525d08d6e5fb8d27136e95/i/m/image_2955.jpg',
        name: 'Macadãmia',
        peso: 8,
        valor: '78,90',
    },
    {
        img: 'https://www.sitiodamata.com.br/media/catalog/product/cache/1/image/9df78eab33525d08d6e5fb8d27136e95/s/e/semente-mamao-papaya-hawaii-carica-papaya-02.jpg',
        name: 'Mamão',
        peso: 8,
        valor: '78,90',
    },
    {
        img: 'https://http2.mlstatic.com/muda-rara-de-mabolo-velvet-apple-kamagong-limitado-D_NQ_NP_669501-MLB20362074672_072015-F.jpg',
        name: 'Mabolo',
        peso: 8,
        valor: '78,90',
    },
]

class BuscaCategoria extends Component {
    constructor(props){
        super(props);
        this.state = {
            search: 'pedido'
        }
    }
    render(){
        return (
            <Container fluid>
                <h4 className="title">Frutas</h4>
                <Row>
                {
                    itensPedidos.map(
                        item => (
                            <Col lg={4}>
                                <ItemPedido item={item}/>
                            </Col>
                        )
                    )
                }
                </Row>
            </Container>
        );
    }
}
export default BuscaCategoria;