import React, { Component } from 'react';
import { withApollo, graphql, Query } from 'react-apollo';
import { PRODUCTS } from '../services/graphql/querys/rodada';
import { 
    Container, 
    Row,
    Col
} from 'react-bootstrap/';
import Categoria from "../component/Categoria";
import ProdutoCard from "../component/ProdutoCard";

class HomeRodada extends Component {
    constructor(props){
        super(props);
        this.state = {
            categorias: null,
            produtos: null,
            category_id: !!this.props.match.params.categoryId && this.props.match.params.categoryId > 0 ? this.props.match.params.categoryId : null,
            id: this.props.match.params.id,
        }
        localStorage.setItem('roundId', this.props.match.params.id);
    }
    fazBusca = () => {
            let paramsEnviar = Object.entries(this.state).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
            console.log("Enviar Parametros: ", paramsEnviar);
            this.props.client.query({query: PRODUCTS, variables: paramsEnviar, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                } else {
                    console.log("DATA: ", data)
                    this.setState({'categorias': data.ProductCategoryQuery});                    
                    this.setState({'produtos': data.MyRoundsAsBuyerQuery.data[0].offered_products.data});   
                }
            })
    }
    componentDidMount(){
        this.fazBusca();        
    }
    render(){
        return (
            <Container fluid className="search-form">
                <h4>Categorias</h4>
                <Row className="contentCategorias">
                    {!!this.state.categorias && this.state.categorias.map(categoria => (
                        <Col xs={3} sm={3} key={`cat-${categoria.product_category_id}`}>
                            <Categoria roundId={this.props.match.params.id} categoria={categoria} />
                        </Col>
                    ))}
                    <Col xs={3} sm={3} >
                            <Categoria roundId={this.props.match.params.id} categoria="TODAS" />
                    </Col>
                </Row>
                <h4>Produtos</h4>
                <Row className="contentProdutoCard">
                    {!!this.state.produtos && this.state.produtos.map(produto => (
                        <Col xs={6} sm={6} lg={3} key={`product-${produto.product.product_id}`}>
                            <ProdutoCard produto={produto} roundId={this.props.match.params.id}/>
                        </Col>
                    ))}
                </Row>
            </Container>
        )
    }
}

export default withApollo(HomeRodada);