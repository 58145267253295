import React, { Component } from 'react';
import { 
    Card, 
    Button
} from 'react-bootstrap/';

class Rodada extends Component {
    render(){
        return (
            <Card className="cardRodada">
                <a href={"/home-rodada/" + this.props.rodada.id} >
                    <Card.Body>
                        <Card.Title>
                            <div>
                                <sub>Rodada</sub>
                            </div>
                            <div>{this.props.rodada.description}</div>
                        </Card.Title>
                        <Card.Text>
                            <div>
                                <sub>INICIO:</sub>
                                <div>{this.props.rodada.order_starts_on}</div>
                            </div>
                            <div>
                                <sub>TERMINO:</sub>
                                <div>{this.props.rodada.order_ends_on}</div>
                            </div>
                        </Card.Text>
                    </Card.Body>
                </a>
            </Card>
        )
    }
}
export default Rodada;