import React, { Component } from 'react';
import { Media } from 'react-bootstrap/';

class ItemPedido extends Component {
    render(){
        console.log(this.props)
        return (
            <a href="/produto" className="boxLineGrey link" >
                <Media>
                    <img 
                        width={52}
                        height={52}
                        className="mr-3 borderRadius-5"
                        src={this.props.item.product.picture ? this.props.item.product.urlPicture : 'https://crestana.com.br/img/imagens_produto/20190726_200300_1____01%20PRODUTO-SEM-IMAGEM-1000X1000.JPG'}
                        alt="Generic placeholder"/>
                    <Media.Body>
                        <div>{this.props.item.product.comercial_name}</div>
                        
                        {
                            this.props.recebido ?
                            <div><strong>Quantidade Enviada:</strong> {this.props.item.quantity.toFixed(3).replace(".",",")}kg</div>
                            :
                            <div><strong>Quantidade Pedida:</strong> {(this.props.item.quantity + this.props.item.quantity_removed).toFixed(3).replace(".",",")}kg</div>
                        }
                        {
                            this.props.recebido ?
                            <div><strong>Valor:</strong> R${((this.props.item.quantity) * this.props.item.unit_price).toFixed(2)}</div>
                            :
                            <div><strong>Valor:</strong> R${((this.props.item.quantity + this.props.item.quantity_removed) * this.props.item.unit_price).toFixed(2)}</div>
                        }
                        <div>

                        </div>
                    </Media.Body>
                </Media>
            </a>
        );
    }
}
export default ItemPedido;