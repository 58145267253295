import React, { Component } from 'react';
import { withApollo, graphql, Query } from 'react-apollo';
import { 
    Button, 
    Container, 
    Row, 
    Col 
} from 'react-bootstrap/';

import Rodada from "../component/Rodada"

import {  RODADAS_QUERY  } from '../services/graphql/querys/rodada';

class Rodadas extends Component {
    constructor(props){
        super(props);
        this.state = {
            rodadas: null,
        }
        this.trogleMenu = this.trogleMenu.bind(this);
        localStorage.removeItem('roundId');
    }
    trogleMenu = () => {
        this.props.trogleMenu()
    }

    buscaRodadas(){
        let paramsEnviar = {
            started: true, 
            ended: false, 
            page:1, 
            limit: 50
        }
        this.props.client.query({query: RODADAS_QUERY, variables: paramsEnviar, errorPolicy: 'all', fetchPolicy:"network-only"})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
            } else {
                if(data && data.MyRoundsAsBuyerQuery){
                    console.log("resp query: ",data.MyRoundsAsBuyerQuery.data)
                    this.setState({rodadas: data.MyRoundsAsBuyerQuery.data})
                }
            }
        })
    }

    componentWillMount(){
        console.log("CHEGUEI NO MOUNT")
        this.buscaRodadas();
        setInterval( () => {
            this.buscaRodadas();
        }, 120000);
    }
    render(){
        return (
            <Container fluid>
                <h4>Rodadas</h4>
                <Row>
                    {
                        !!this.state.rodadas && this.state.rodadas.map(rodada => (
                           rodada.open_to_order && 
                            <Col xl={3} lg={4} sm={6} xs={12} key={`col-${rodada.id}`}>
                                <Rodada rodada={rodada} />
                            </Col>
                        ))
                    }
                </Row>
            </Container>
        );
    }
}
export default withApollo(Rodadas);