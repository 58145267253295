import React, { Component } from 'react';
import { Nav, Form, Media, Button } from 'react-bootstrap/';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';

class Template extends Component {
    constructor(props){
        super(props);
        this.state = {
            value: 0
        }
        this.trogleMenu = this.trogleMenu.bind(this);
    }
    trogleMenu = () => {
        this.props.trogleMenu()
    }
    render(){
        console.log("MEUS PROPS", this.props)
        return (
            <>
                <div className="Header bgGrey">
                    <div className="Header bgGreen">
                        <center className="contentHeader">
                            <div className="colorWhite">
                                Boleto em aberto. <strong>  Você tem 3 dias antes que sua compra expire.</strong>
                            </div>

                            <div className="contentButton">
                                <Button onClick={() => this.trogleMenu()} variant="outline-light">VER BOLETO</Button>
                            </div>

                            <sub className="colorWhite">
                                O pagamento é aprovado em 1 ou 2 dias úteis
                            </sub>
                        </center>
                    </div>
                    <div className="Content">
                        <center>
                        <div>
                            <strong>Total da compra: R$317,75</strong>
                        </div>
                        <div>
                            Pedido Nº<strong>09480988</strong>
                        </div>
                        <div>
                            Data de entrega: Sexta dia 21/12/2019
                        </div>
                        </center>
                    </div>
                </div>
                <div className="Header bgGrey">
                    <div className="Header bgRed">
                        <center className="contentHeader">
                        <div className="colorWhite">
                            Boleto em aberto. <span>Você precisa pagar hoje antes que sua compra expire.</span>
                        </div>

                        <div className="contentButton">
                            <Button variant="outline-light">VER BOLETO</Button>
                        </div>

                        <sub className="colorWhite">
                            O pagamento é aprovado em 1 ou 2 dias úteis
                        </sub>
                        </center>
                    </div>
                    <div className="Content">
                        <center>
                        <div>
                            <strong>Total da compra: R$317,75</strong>
                        </div>
                        <div>
                            Pedido Nº<strong>09480988</strong>
                        </div>
                        <div>
                            Data de entrega: Sexta dia 21/12/2019
                        </div>
                        </center>
                    </div>
                </div>
                <div className="Content">
                    <h1>H1 - Title</h1>
                    <h2>H2 - Title</h2>
                    <h3>H3 - Title</h3>
                    <h4 className="title">H4 - Title</h4>
                    <h5>H5 - Title</h5>
                    <h6>H6 - Title</h6>

                    <Nav variant="pills" defaultActiveKey="/home">
                        <Nav.Item>
                        <Nav.Link href="/home">Pedido</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="link-1">Boleto</Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Data Inicial</Form.Label>
                            <Form.Control type="email" placeholder="GG/MM/AAAA" />
                        </Form.Group>
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Pagos" />
                        </Form.Group>
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Não Pagos" />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Example select</Form.Label>
                            <Form.Control as="select">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </Form.Control>
                        </Form.Group>
                    </Form>
                    <RangeSlider
                        value={this.state.value}
                        onChange={changeEvent => this.setState({value: changeEvent.target.value})}
                        variant='custon'
                        size='lg'
                        tooltip='off'
                    />
                    <p>lorem is punt lef or main to abraver lorem is punt lef or main to abraver </p>
                    <div className="boxLineGrey">
                        <Media>
                            <img 
                                width={52}
                                height={52}
                                className="mr-3 borderRadius-5"
                                src="https://cdn.awsli.com.br/600x450/998/998380/produto/36789644/c68cedb8d6.jpg"
                                alt="Generic placeholder"/>
                            <Media.Body>
                                <div>Cebola roxa</div>
                                <div>
                                    <strong>8kg - R$78,90</strong>
                                </div>
                            </Media.Body>
                        </Media>
                    </div>
                    <div className="boxLineGrey">
                        <Media>
                        <img 
                            width={52}
                            height={52}
                            className="mr-3 borderRadius-5"
                            src="https://www.sitiodamata.com.br/media/catalog/product/cache/1/image/9df78eab33525d08d6e5fb8d27136e95/l/a/laranja-pera-do-rio-citrus-sinensis.png"
                            alt="Generic placeholder"/>
                            <Media.Body>
                                <div>Laranja pera</div>
                                <div>
                                    <strong>20kg - R$150,55</strong>
                                </div>
                            </Media.Body>
                        </Media>
                    </div>
                    <div className="boxLineGreen">
                        Seu pedido <strong>Nº 09480988</strong>
                        <br />
                        <strong>chega hoje =)</strong>
                    </div>
                    <div className="boxLineRed">
                        Boleto em aberto. <span>Você precisa pagar hoje antes que sua compra expire</span>.
                    </div>
                    {/* <a href="#">LINK</a> */}
                </div>
            </>
        );
    }
}
export default Template;