import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap/';
import ItemPedido from "../component/ItemPedido";
import { withApollo, graphql, Query } from 'react-apollo';
import ProdutoCard from "../component/ProdutoCard";

import { PRODUCTS } from '../services/graphql/querys/rodada';

class BuscaProduto extends Component {
    constructor(props){
        super(props);
        this.state = {
            produtos: null,
            id: this.props.match.params.id,
            filter: this.props.match.params.buscar,
        }
    }
    componentDidMount(){
            let paramsEnviar = Object.entries(this.state).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
            this.props.client.query({query: PRODUCTS,variables: paramsEnviar ,errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                } else {
                    console.log("resp query: ",data)
                    this.setState({'produtos': data.MyRoundsAsBuyerQuery.data[0].offered_products.data});   
                }
            })
    }
    render(){
        return (
            <Container fluid>
                <h4 className="title">Produtos</h4>
                <Row className="contentProdutoCard">
                    {!!this.state.produtos && this.state.produtos.map(produto => (
                        <Col xs={6} sm={6} lg={3} key={`product-${produto.product.product_id}`}>
                            <ProdutoCard produto={produto} roundId={this.props.match.params.id}/>
                        </Col>
                    ))}
                </Row>
            </Container>
        );
    }
}
export default withApollo(BuscaProduto);