import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import React, { Component, Fragment } from 'react';
import Template from './pages/Template';
import MeusPedidos from './pages/MeusPedidos';
// import Boletos from './pages/Boletos';
import DetalheCompra from './pages/DetalheCompra';
// import BuscaPedidoBoleto from './pages/BuscaPedidoBoleto';
import BuscaProduto from './pages/BuscaProduto';
import BuscaCategoria from './pages/BuscaCategoria';
// import FiltroPedido from './pages/FiltroPedido';
import Carrinho from './pages/Carrinho';
import PedidoConcluido from './pages/PedidoConcluido';
import HomeRodada from './pages/HomeRodada';
import Produto from './pages/Produto';
import Rodadas from './pages/Rodadas';
import Login from "./pages/Login";

// Component
import Header from "./component/Header";
import Busca from "./component/Busca";
import Menu from './component/Menu';

const Layout = ( props ) => {
    console.log("CONST PROPS: ", props)
    if(props.location.pathname !== "/login") {
        return(
            <div className={`App contentPages ${props.location.pathname === "/pedido-concluido" && ' pagePedidoCompleto'}`}>
                <Header 
                    trogleMenu={props.trogleMenu}
                    location={props.location}
                />
                {
                (props.location.pathname.indexOf("/home-rodada") > -1 || props.location.pathname.indexOf("/busca-produto") > -1) && 
                <Busca/>
                }
                {props.children}
            </div>
        );
    } else {
        return props.children;
    }
}

class Routes extends Component {
    constructor(props){
        super(props);
        this.state = {
          menuActive: false,
        }
    }

    trogleMenu = () => {
        this.setState({menuActive: !this.state.menuActive})
    }


    
    render(){
        let UserAccount = localStorage.getItem('UserAccount') ? JSON.parse(localStorage.getItem('UserAccount')) : undefined;
        console.log("UserAccount: ",UserAccount);
        return (
            <BrowserRouter>
                <Fragment>
                    <Menu 
                        trogleMenu={this.trogleMenu}
                        menuActive={this.state.menuActive}
                    />
                    <Switch>
                        <Route path={`${process.env.PUBLIC_URL}/login`} exact component={() => <Login/>} />
                        {!UserAccount &&
                            <Redirect to="/login"/>
                        }
                    </Switch>
                    <Switch>
                        <Layout trogleMenu={this.trogleMenu} >
                            <Route path="/" exact component={Rodadas} />
                            <Route path="/meus-pedidos" exact component={MeusPedidos} />
                            <Route path="/detalhe-compra/:id" exact component={DetalheCompra} />
                            <Route path="/busca-produto/:id/:buscar" exact component={BuscaProduto} />
                            <Route path="/busca-categoria" exact component={BuscaCategoria} />
                            <Route path="/carrinho/:id" exact component={Carrinho} />
                            <Route path="/pedido-concluido/:id" exact component={PedidoConcluido} />
                            <Route path="/home-rodada/:id" exact component={HomeRodada} />
                            <Route path="/home-rodada/:id/:categoryId" exact component={HomeRodada}   />
                            <Route path="/produto" exact component={Produto} />
                            <Route path="/produto/:id/:product_id" exact component={Produto} />
                        </Layout>
                        <Route path="*" component={() => <h1>Page not found</h1>} />
                    </Switch>
                </Fragment>
            </BrowserRouter>
        );
    }
}
export default Routes;