import React, { Component } from 'react';
import { withApollo, graphql, Query } from 'react-apollo';
import { Container, Row, Col, Button } from 'react-bootstrap/';


class PedidoConcluido extends Component {
    constructor(props){
        super(props);
    }
    render(){
        return (
            <Container fluid  className={`pedidoComplete`}>
                <h3 className="title">Pedido efetuado com sucesso</h3>
                <div>
                    Nº de pedido {this.props.match.params.id}. 
                    Seu pedido será realizado mediante o pagamento.
                    Você tem 3 dias para pagar antes que expire.
                </div>

                <div>
                     <Button variant="custom" href="/meus-pedidos/">Meus Pedidos</Button>
                </div>
            </Container>
        )
    }
}
export default withApollo(PedidoConcluido);