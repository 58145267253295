import React, { Component } from 'react';
import { 
    Container, 
    InputGroup,
    FormControl,
    Button
 } from 'react-bootstrap/';

class Busca extends Component {
    constructor(props){
        super(props);
        this.state = {
            busca: '',
            roundId: localStorage.getItem('roundId'),
        }
    }
    render(){
        return (
            <Container fluid className="search-form">
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <Button variant="outline-secondary" href={this.state.busca ? `/busca-produto/${this.state.roundId}/${this.state.busca}` : `#`}>
                            <span className="search-icon"></span>
                        </Button>
                    </InputGroup.Prepend>
                    <FormControl aria-describedby="basic-addon1" placeholder="Buscar no Circuito Agroecológicos" value={this.state.busca} onChange={(e) => this.setState({busca: e.target.value})} />
                </InputGroup>
            </Container>
        )
    }
}

export default Busca;