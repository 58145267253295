import React, { Component } from 'react';
import { Media, Form, Row, Col } from 'react-bootstrap/';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';

class ItemPedido extends Component {
    constructor(props){
        super(props);
        this.state = {
            produto: this.props.item,
            price: this.props.item.product.unit_price,
            qtd: this.props.item.qtd,
            max: this.props.item.product.available_quantity,
        }
    }
    peso = (value) => {
		let v = value,
			integer = v.split(',')[0];


		v = v.replace(/\D/, "");
		v = v.replace(/^[0]+/, "");

		if(v.length <= 3 || !integer)
		{
            console.log(v)
			if(v.length === 1) v = '0.00' + v;
			if(v.length === 2) v = '0.0' + v;
			if(v.length === 3) v = '0.' + v;
			//if(v.length === 4) v = '0.' + v;
		} else {
            v = v.replace(/^(\d{1,})(\d{3})$/, "$1.$2");
            console.log(parseFloat(v) < parseFloat(this.state.max))
        }
        if(parseFloat(v) < parseFloat(this.state.max)){
            this.setState({qtd:parseFloat(v).toFixed(3)},this.props.changeQtd(this.props.indice, parseFloat(v).toFixed(3)));
        } else if(v === "") {
            this.setState({qtd: 0});
        } else {
            this.setState({qtd:this.state.max.toFixed(3)});
        }
		//this.setState({qtd:v});
	}
    render(){
        if(!this.state.produto){
            return <div></div>;
        }else{
            return (
                <div className="boxLineGrey alignCenter">
                    <Media>
                        <img 
                            width={80}
                            height={80}
                            className="mr-3 borderRadius-5"
                            src={this.state.produto.product.product.picture ? this.state.produto.product.product.urlPicture : 'https://crestana.com.br/img/imagens_produto/20190726_200300_1____01%20PRODUTO-SEM-IMAGEM-1000X1000.JPG'}
                            alt="Generic placeholder"/>
                        <Media.Body>
                            <div>{this.state.produto.product.product.comercial_name}</div>
                            <Row>
                                {/* <Col>
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        <RangeSlider
                                            value={this.state.qtd}
                                            onChange={changeEvent => {this.setState({qtd: (changeEvent.target.value)}); this.props.changeQtd(this.props.indice,(changeEvent.target.value))}}
                                            max={this.state.max}
                                            variant='custon'
                                            size='xs'
                                            tooltip='off'
                                        />{this.state.qtd } KG
                                    </Form.Group>
                                </Col> */}
                                <Col className="itemValor">
                                    <strong>R${(this.state.price * this.state.qtd).toFixed(2)}</strong>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <input type="number"  
                                        max={this.state.max}
                                        step={0.001}
                                        onChange={(e) => {e.target.value && this.peso(e.target.value)}}
                                        value={this.state.qtd}/>
                                        kg
                                </Col>
                            </Row>
                        </Media.Body>
                    </Media>
                </div>
            );
        }
    }
}
export default ItemPedido;