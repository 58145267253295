import React, { Component } from 'react';
import { 
    Card, 
    Button
} from 'react-bootstrap/';

class ProdutoCard extends Component {
    render(){
        return (
            <Card>
                <a href={`/produto/` + this.props.roundId + `/` + this.props.produto.product.product_id}>
                    <Card.Img variant="top" src={this.props.produto.product.picture ? this.props.produto.product.urlPicture : 'https://crestana.com.br/img/imagens_produto/20190726_200300_1____01%20PRODUTO-SEM-IMAGEM-1000X1000.JPG'} />
                    <Card.Body>
                        <Card.Title>{this.props.produto.product.comercial_name}</Card.Title>
                        <Card.Text>R$ {this.props.produto.unit_price} kg</Card.Text>
                    </Card.Body>
                </a>
            </Card>
        )
    }
}
export default ProdutoCard;