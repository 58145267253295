import gql from 'graphql-tag';

export const RODADAS_QUERY = gql`
query MyRoundsAsBuyerQuery($started: Boolean, $ended: Boolean, $page: Int, $limit: Int){
  MyRoundsAsBuyerQuery(started: $started, ended: $ended, page: $page, limit: $limit){
    data {
      id
			description
      status {
        code
        type
        description         
      }
      offer_starts_on
      offer_ends_on
      order_starts_on
      order_ends_on
      open_to_offer
      open_to_order
    }
  }
}`;

export const PRODUCTS = gql`
query MyRoundsAsBuyerQuery($id: Int, $category_id: Int, $product_id: Int, $filter: String ) {
  MyRoundsAsBuyerQuery(id: $id) {
    data {
      offered_products(category_id: $category_id, product_id: $product_id, filter: $filter, limit: 400) {
        data {
          available_quantity
          unit_price
          product {
            product_id
            local_name
            comercial_name
            picture
            urlPicture
            productCategory {
              product_category_id
              product_category
            }
          }
        }
      }
    }
  }
  ProductCategoryQuery {
    product_category_id
    product_category
  }
}

`;