import gql from 'graphql-tag';

export const FAZ_PEDIDO = gql`
mutation createPurchaseOrder($commercial_round_id: Int!, $products: [PurchaseProductInput]){
  createPurchaseOrder(commercial_round_id: $commercial_round_id, products: $products){
    id
    status{
      code
      type
      description
    }
    items{
      id
      status{
        code
        type
        description
        posible_statuses{
          code
          type
          description
        }
      }
      quantity
      unit_price
      product{
        product_id
        comercial_name
      }
    }
  }
}
`;

export const INFORMA_RECEBIMENTO = gql`
mutation editPurchaseOrder($id: Int, $status_code: String){
  editPurchaseOrder(id: $id, status_code: $status_code){
    id
  }
}`;

