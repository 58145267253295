import React, { Component } from 'react';
import { Media, Button } from 'react-bootstrap/';

import { Sticker, Close } from "../assets/svg/SVG";

class Menu extends Component {
    render(){
        return (
            <div className={`mainMenu ${this.props.menuActive?'active':''}`}>
                <Button className="btnClose" variant="link" onClick={() => this.props.trogleMenu()}>
                    {Close}
                </Button>
                <ul className="mainMenuList">
                    <Media as="li">
                        <span
                            className="mr-3 borderCicle">
                            {Sticker}
                        </span>
                        <Media.Body>
                            <a href="/">Rodada</a>
                        </Media.Body>
                    </Media>
                    <Media as="li">
                        <span
                            className="mr-3 borderCicle">
                            {Sticker}
                        </span>
                        <Media.Body>
                        <a href="/meus-pedidos">Pedidos</a>
                        </Media.Body>
                    </Media>
                    <Media as="li">
                        <span
                            className="mr-3 borderCicle">
                            {Sticker}
                        </span>
                        <Media.Body>
                            <a href="/login">Sair</a>
                        </Media.Body>
                    </Media>
                </ul>
            </div>
        )
    }
}
export default Menu;