import React, { Component } from 'react';

import Logo from "../assets/images/logo.png";
import { FiAlignCenter } from 'react-icons/fi';
class Header extends Component {
    constructor(props){
        super(props);
        this.state = {
            id: localStorage.getItem('roundId'),
            ucc: null,
        }
    }

    qtdItens(){
        let chart = localStorage.getItem('chart') ? JSON.parse(localStorage.getItem('chart')) : null;
        console.log("Chart: ", chart)
        if(!chart || !chart[this.state.id]){
            return 0;
        }else{
            return chart[this.state.id].length;
        }
    }
    componentDidMount(){
        let UserAccount = localStorage.getItem('UserAccount') ? JSON.parse(localStorage.getItem('UserAccount')) : undefined;
        console.log("UserAcc: ", UserAccount)
        this.setState({ucc: UserAccount})
    }
    render(){
        console.log("props: ", this.props)
        return (
            <>
            <nav className="navbar navbar-dark">
                <button className="navbar-toggler" type="button" onClick={this.props.trogleMenu}>
                    <span className="navbar-toggler-icon"></span>
                </button>

                <img 
                    className="mr-3"
                    src={Logo}
                    alt="ESTAÇÅO ORGÂNICA"
                />
                {this.state && this.state.id &&
                    <a className="navbar-toggler" href={"/carrinho/" + this.state.id} type="button">
                        <span className="cart-icon"></span>
                        <span className="num-cart">{this.qtdItens()}</span>
                    </a>
                }
            </nav>
            
        {!!this.state.ucc && !!this.state.ucc.profile && 
            <nav style={{alignSelf: "center"}}>
                {this.state.ucc.profile.name}
            </nav>
        }
        
        </>
        )
    }
}

export default Header;