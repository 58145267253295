import React, { Component } from 'react';
import { FiChevronRight } from "react-icons/fi";
import { withApollo, graphql, Query } from 'react-apollo';
import {  INFORMA_RECEBIMENTO  } from '../services/graphql/mutations/pedido';
import {  
    Media,
    Form,
 } from 'react-bootstrap/';

class ItemListaPedido extends Component {

    changeStatus(status){
        this.props.client.mutate({
            mutation: INFORMA_RECEBIMENTO, 
            variables: {
                id: this.props.pedido.id,
                status_code: status
            }, 
            errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
            } else {
                console.log("DATA: ", data);
                window.location.href = "/meus-pedidos";
            }
        }) 
    }

    render(){
        // if(this.props.pedido.status){
            return (
                <div className="boxLineGrey">
                    <a href={"/detalhe-compra/" + this.props.pedido.id}>
                        <Media>
                            <Media.Body>
                                <div>Nome Rodada: <strong>{this.props.pedido.commercial_round.description}</strong></div>
                                <div>Nº do Pedido: <strong>{this.props.pedido.id}</strong></div>
                                <div>Data: <strong>{this.props.pedido.created_at}</strong></div>
                                <div>
                                    Status: <strong>{this.props.pedido.status && this.props.pedido.status.description}</strong>
                                </div>
                            </Media.Body>
                            <div className="mr-3 iconNext">
                                <FiChevronRight/>
                            </div>
                        </Media>
                    </a>
                    {this.props.pedido.status && this.props.pedido.status.code === "sended" && 
                        <div>
                            Ja recebi o Pedido 
                            <Form.Check 
                                type="checkbox"
                                inline
                                onChange={(e) => this.changeStatus("delivered")}
                            />

                        </div>
                    }
                </div>
            );
        //} else {
        //    return false
        //}
    }
}
export default withApollo(ItemListaPedido);