import React, { Component } from 'react';
import  { Redirect } from 'react-router-dom'
import { 
    Container, 
    Form,
    Button
} from 'react-bootstrap/';
import { withApollo, graphql, Query } from 'react-apollo';
import { LOGIN } from '../services/graphql/mutations/login';

import Logo from "../assets/images/logo.png";

class Login extends Component {
    constructor(props){
        super(props);    
        this.state = {
            login: '',
            password: '',
            mensage: ''
        }
    }
    submitIt = () => {
        this.setState({open: false});
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        console.log("Enviar Parametros: ", paramsEnviar);

        this.props.client.mutate(
            {
                mutation: LOGIN, 
                variables: paramsEnviar,
                errorPolicy: 'all',
            }
        )
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                this.setState({
                    style:'error',
                    mensage: 'Login ou senha invalido!'
                })
               
            } else {
                console.log("DATA: ", data);
                localStorage.setItem('UserAccount', JSON.stringify(data.login));
                localStorage.setItem('user_token', data.login.access_token);
                this.setState({
                    style:'success',
                    mensage: 'Você foi logado com sucesso'
                })
                
                setTimeout(function () {
                    // if(data.login.profile.user_type == "admin_central"){
                    //     window.location.href = "/usuarios";
                    // } else if(data.login.profile.user_type == "admin_station"){
                    //     window.location.href = "/consumidores";
                    // }
                    window.location.href = "/";
                }, 2000);
                
                // alert("Salvo com sucesso !");
            }
        })
    }
    render(){
        console.log(this.state)
        return (
            <Container fluid className="login-form">
                <Form>
                    <Form.Group className="btnCenter">
                        <img 
                        className="mr-3"
                        src={Logo}
                        alt="ESTAÇÅO ORGÂNICA"/>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Nome de usuario</Form.Label>
                        <Form.Control  id="name"  value={this.state.login} onChange={(e) => this.setState({login: e.target.value})} name="name" type="text" placeholder="Nome de usuario" />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control  id="pass"  name="pass" value={this.state.password} onChange={(e) => this.setState({password: e.target.value})} type="password" placeholder="Password" />
                    </Form.Group>
                    <Form.Group className="btnCenter" controlId="formBasicEmail">
                        <Button variant="primary" type="button" onClick={() => this.submitIt()}>
                            Submit
                        </Button>
                    </Form.Group>
                </Form>
            </Container>
        )
    }
}

export default withApollo(Login);