import React, { Component } from 'react';
import { withApollo, graphql, Query } from 'react-apollo';
import { PRODUCTS } from '../services/graphql/querys/rodada';
import { 
    Table,
    Navbar,
    Nav,
    Card, 
    Button
} from 'react-bootstrap/';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import InputMask from "react-input-mask";

class Produto extends Component {
    constructor(props){
        super(props);
        this.state = {
            value: 0.0,
            product_id: this.props.match.params.product_id,
            id: this.props.match.params.id,
            produto: null,
        }
    }
    peso = (value) => {
		let v = value,
        integer = v.split(',')[0];

		v = v.replace(/\D/, "");
		v = v.replace(/^[0]+/, "");
		if(v.length <= 3 || !integer)
		{
			if(v.length === 1) v = '0.00' + v;
			if(v.length === 2) v = '0.0' + v;
			if(v.length === 3) v = '0.' + v;
		} else {
            v = v.replace(/^(\d{1,})(\d{3})$/, "$1.$2");
        }
        if(parseFloat(v) < this.state.produto.available_quantity.toFixed(3)){
            this.setState({value:parseFloat(v).toFixed(3)});
        } else if(v === "") {
            this.setState({value: 0});
        } else {
            this.setState({value:this.state.produto.available_quantity.toFixed(3)});
        }
	}

    componentDidMount() {
        let paramsEnviar = Object.entries(this.state).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
            console.log("Enviar Parametros: ", paramsEnviar);
            this.props.client.query({query: PRODUCTS, variables: paramsEnviar, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                } else {
                    console.log("DATA: ", data)
                    this.setState({'produto': data.MyRoundsAsBuyerQuery.data[0].offered_products.data[0]});
                }
            })
    }

    addToChart(){
        let chart = localStorage.getItem('chart') ? JSON.parse(localStorage.getItem('chart')) : null;
        console.log("Chart: ", chart)
        console.log("Adicionando valor: ", this.state.value)
        if(!chart){
            let chart = [];
            chart[this.state.id] = [];
            chart[this.state.id].push({qtd: this.state.value, product: this.state.produto});
            localStorage.setItem('chart', JSON.stringify(chart));
            alert("Adicionado ao carrinho com sucesso !");
        }else if(!chart[this.state.id]){
             chart[this.state.id] = [];
             chart[this.state.id].push({qtd: this.state.value, product: this.state.produto});
             localStorage.setItem('chart', JSON.stringify(chart));
             alert("Adicionado ao carrinho com sucesso !");
        }else{
            let filter = chart[this.state.id].filter( ch => ch.product.product.product_id == this.state.produto.product.product_id)
            if(filter.length == 0){ 
                chart[this.state.id].push({qtd: this.state.value, product: this.state.produto});
                localStorage.setItem('chart', JSON.stringify(chart));
                alert("Adicionado ao carrinho com sucesso !")
            }else{
                alert("Produto ja esta no carrinho");
            }
        }
        window.location.href = "/home-rodada/" + this.state.id;
    }

    render(){
        if(!!this.state.produto){
            return (
                <div
                    className="pageProduto"
                    style={{
                        backgroundImage: `url(${this.state.produto.product.picture ? this.state.produto.product.urlPicture : false})`,
                    }}
                >
                    <div className="contentProdutoInfo">
                        <div className="tagView">
                            <span>
                                Estoque: <strong>{this.state.produto.available_quantity.toFixed(3).replace(".",",")}kg</strong>
                            </span>
                        </div>
                        <div className="contentProduto">
                            <h4>{this.state.produto.product.comercial_name}</h4>
                            <div className="precoProduto">R${this.state.produto.unit_price}/kg</div>
                            <h6>Escolha a quantidade</h6>
                            <div className="description">Toque e arraste para selecionar quantos kilos do produto você deseja</div>
                            {/* <RangeSlider
                                value={this.state.value}
                                onChange={changeEvent => this.setState({value: changeEvent.target.value})}
                                max={this.state.produto.available_quantity*1000}
                                variant='custon'
                                size='lg'
                                tooltip='off'
                            /> */}
                            <div className="contemPesoValor">
                                <div className="contemPeso">
                                    {/* <input type="number"  
                                        max={this.state.produto.available_quantity}
                                        step={0.1}
                                        onChange={(e) => {e.target.value <= this.state.produto.available_quantity && this.setState({value: e.target.value*1000})}}
                                        value={this.state.value/1000}
                                    /> */}
                                    <input type="number"
                                        max={this.state.produto.available_quantity}
                                        step={0.001}
                                        onChange={(e) => { e.target.value && this.peso(e.target.value) }}
                                        value={ this.state.value}
                                    />
                                    kg
                                    </div>
                                <div className="contemValor">R${((this.state.produto.unit_price*this.state.value)).toFixed(2)}</div>
                            </div>
                        </div>
                        <div className="contemFinalizaPedido">
                            <Button className="contemPeso" onClick={() => this.addToChart()}>ADICIONAR AO CARRINHO</Button>
                            <Button onClick={() => this.addToChart()} href={"/carrinho/" + this.state.id} className="contemValor">FINALIZAR PEDIDO</Button>
                        </div>

                        <div style={{
                            marginTop: "1rem", 
                            marginBottom: "5px", 
                            border: "2px solid #dee2e6",
                            borderTop: "1px solid #dee2e6", 
                            overflowY: "scroll", 
                            height:"50%" 
                        }}>
                            <Table striped  style={{flex: 1, fontSize: "10px"}}>
                                <thead>
                                    <tr>
                                        <th colSpan="2" style={{textAlign: "center", fontSize: "13px"}}>Tabela de conversão - Estação Orgânica</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Hortaliças</th>
                                        <th>100 a 250 gramas</th>
                                    </tr>
                                    <tr>
                                        <th>Couve, espinafre, brócolis e couve flor</th>
                                        <th>300 a 500 gramas</th>
                                    </tr>
                                    <tr>
                                        <th>Maços de temperos</th>
                                        <th>100 a 150 gramas</th>
                                    </tr>
                                    <tr>
                                        <th>1 cabeça de alho</th>
                                        <th>50 a 100 gramas</th>
                                    </tr>
                                    <tr>
                                        <th>1 cebola média</th>
                                        <th>100 a 250 gramas</th>
                                    </tr>
                                    <tr>
                                        <th>Legumes grandes (abóbora cabotiã, mandioca)</th>
                                        <th>1 a 2 kilos</th>
                                    </tr>
                                    <tr>
                                        <th>Legumes pequenos (abobrinha italiana, tomate)</th>
                                        <th>100 a 300 gramas</th>
                                    </tr>
                                    <tr>
                                        <th>Frutas Grandes (Abacate, abacaxi, manga)</th>
                                        <th>400 a 1000 gramas</th>
                                    </tr>
                                    <tr>
                                        <th>Frutas Pequenas (Laranja, maça, pinha)</th>
                                        <th>200 a 400 gramas</th>
                                    </tr>
                                    <tr>
                                        <th>1 penca de banana</th>
                                        <th>1 a 2 kilos</th>
                                    </tr>
                                    <tr>
                                        <th>1 bandeja de morango</th>
                                        <th>250 gramas</th>
                                    </tr>
                                    <tr>
                                        <th><strong>*Pesagens aproximadas</strong></th>
                                    </tr>                        
                                </tbody>
                            </Table>
                            <Table striped  style={{flex: 1, fontSize: "10px"}}>
                                <thead>
                                    <tr>
                                    <th colSpan="2" style={{fontSize: "13px"}}>Converta em KG</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr aria-rowspan="0.5">
                                        <th>KG</th>
                                        <th>1,000</th>
                                    </tr>
                                    <tr>
                                        <th>100 G</th>
                                        <th>0,100</th>
                                    </tr>
                                    <tr>
                                        <th>200 G</th>
                                        <th>0,200</th>
                                    </tr>
                                    <tr>
                                        <th>300 G</th>
                                        <th>0,300</th>
                                    </tr>
                                    <tr>
                                        <th>400 G</th>
                                        <th>0,400</th>
                                    </tr>
                                    <tr>
                                        <th>500 G</th>
                                        <th>0,500</th>
                                    </tr>
                                    <tr>
                                        <th>600 G</th>
                                        <th>0,600</th>
                                    </tr>
                                    <tr>
                                        <th>700 G</th>
                                        <th>0,700</th>
                                    </tr>
                                    
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            )
        }else{
            return (<div></div>);
        }
    }
}
export default withApollo(Produto);